import React from "react";
import TimelineCard from "./TimelineCard";
import centipedeImg from "../../static/image/centeped-img.png";
import mushroomImg from "../../static/image/mushroom-img.png";
import spiderImg from "../../static/image/spider-img.png";
import playerShotImg from "../../static/image/PlayerShot.png";
import fleaImg from "../../static/image/Flea.png";
import mushroomAudio from "../../static/audio/Centipede_Arcade_MushroomPlacement.mp3";
import centipedeAudio from "../../static/audio/Centipede_Arcade_MusicTone.mp3";
import playerShotAudio from "../../static/audio/Centipede_Arcade_Shooting.mp3";
import spiderAudio from "../../static/audio/Centipede_Arcade_PlayerDestroy.mp3";
import fleaAudio from "../../static/audio/Centipede_Arcade_FleaFall.mp3";

import { play } from "../../helpers/audio";
import ImageButton from "../ImageButton";

function TimelineList(props) {
    const playAudio = audio => {
        if (audio) play(audio);
    };

    return (
        <div id="timelinelist-container">
            {bgElements.map((item, i) => (
                <ImageButton
                    id={item.id}
                    src={item.image}
                    onClick={() => {
                        playAudio(item.audio);
                    }}
                />
            ))}

            {props.items.map((item, i) => (
                <TimelineCard
                    key={i}
                    item={item}
                    right={i % 2 === 1}
                    onClickImage={() => {
                        playAudio(item.audio);
                    }}
                />
            ))}

            {!props.hideLine ? (
                <>
                    <div className="timeline-line-left" />
                    <div className="timeline-line-center" />
                    <div className="timeline-line-right" />
                </>
            ) : null}
        </div>
    );
}

const bgElements = [
    {
        id: "centepede-img",
        image: centipedeImg,
        audio: centipedeAudio
    },
    {
        id: "mushroom-img-sm",
        image: mushroomImg,
        audio: mushroomAudio
    },
    {
        id: "mushroom-img",
        image: mushroomImg,
        audio: mushroomAudio
    },
    {
        id: "spider-img",
        image: spiderImg,
        audio: spiderAudio
    },
    {
        id: "player-shot-img",
        image: playerShotImg,
        audio: playerShotAudio
    },
    {
        id: "flea-img",
        image: fleaImg,
        audio: fleaAudio
    }
];

export default TimelineList;
