import React from "react";
import { useTranslation } from "react-i18next";
import ViewOnButton from "./ViewOnButton";

function TileContent1(props) {
    const { t } = useTranslation();

    return (
        <div className="tile-content-container">
            <div>
                <img src={props.logo} alt="" className="logo-small" />
                <div className="bebas-regular mt-1">
                    <div className="text-medium">{t("flagship")}</div>
                    <p className="text-small bebas-sm-spacing">{t(props.mainText)}</p>
                </div>
                <div className="bebas-regular text-medium text-bright-red mb-1">{t(props.available)}</div>
            </div>
            <img src={props.signImg} alt="" />
            <div>
                <div className="bebas-regular text-medium text-bright-red mb-1">{t(props.desc)}</div>
                {props.url ? <ViewOnButton viewOn={props.viewOn} url={props.url} /> : null}
            </div>
        </div>
    );
}

export default TileContent1;
