import React from "react";

function ImageButton(props) {
    return (
        <button id={props.id} className={"hide-btn "+props.btnClassName} onClick={props.onClick}>
            <img src={props.src} alt="" className={props.imgClassName} />
        </button>
    );
}

export default ImageButton;
