import React from "react";
import { useTranslation } from "react-i18next";
import ViewOnButton from "./ViewOnButton";

function TileContent2(props) {
    const { t } = useTranslation();

    return (
        <div className="tile-content-container">
            <div className="bebas-regular mt-1">
                <div className="text-medium">{t(props.title)}</div>
                <p className="text-medium text-grey">{t(props.available)}</p>
            </div>
            <div className="d-flex align-items-center">
                <span
                    className={`tile-status bebas-regular no-wrap bebas-sm-spacing ${
                        props.descClass
                    } ${props.desc === "SOLD OUT" ? "text-red-red" : ""}`}
                >
                    {t(props.desc)}
                </span>
                {props.url ? (
                    <ViewOnButton
                        btnClass="bg-light-grey"
                        viewOn={props.viewOn}
                        url={props.url}
                    />
                ) : null}
            </div>
        </div>
    );
}

export default TileContent2;
