import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function Title(props) {
    const { t } = useTranslation();

    return (
        <Container>
            <Row>
                <Col md={6} id="timeline-title" className="bebas-regular">
                    {t(props.title)}
                </Col>
            </Row>
        </Container>
    );
}

export default Title;
