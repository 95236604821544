import React from "react";
import { Modal } from "react-bootstrap";

function ImageView(props) {
    return (
        <Modal show={props.src} onHide={props.onHide}>
            {props.src ? <img src={props.src} /> : null}
        </Modal>
    );
}

export default ImageView;
