import React, { useState } from "react";
import BrandsLinks from "./BrandsLinks";
import Signup from "./Signup";
import { useTranslation } from "react-i18next";
import discord from "../static/image/Discord.png";
import twitter from "../static/image/socialtwitter.png";
import email from "../static/image/socialemail.png";
import MarketLinks from "./MarketLinks";
import PrivacyPolicyAndTermsOfService from "../components/PrivacyPolicyAndTermsOfService";
import { MAIL_TO } from "../config";

function Footer(props) {
    const [isOpen, setIsOpen] = useState(false);
    const { t } = useTranslation();

    return (
        <div id="footer-2">
            <div id="footer-contents">
                <div>
                    <BrandsLinks />
                    <div id="signup-form-container">
                        <div id="capture-future" className="bebas-regular">
                            <span>{t("CAPTURE FUTURE")}</span>
                            <span>{t("CAPSULE OPENINGS!")}</span>
                        </div>
                        <Signup onClickLink={() => setIsOpen(true)} />
                        <div id="social-links">
                            <a href="https://discord.gg/xZQx8EKy8R" target="_blank">
                                <img src={discord} alt="" />
                            </a>
                            <a href="https://twitter.com/quidd?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor" target="_blank">
                                <img src={twitter} alt="" />
                            </a>
                            <a href={"mailto:" + MAIL_TO} target="_blank">
                                <img src={email} alt="" />
                            </a>
                        </div>
                    </div>
                </div>
                <div>
                    <span className="text-medium no-wrap bebas-regular">{t("JOIN QUIDD TODAY")}</span>
                    <MarketLinks />
                </div>
            </div>
            <div className="pb-4">
                <p className="text-xs text-center mb-0">
                    {t(
                        `© 2021 Atari Interactive, Inc. All rights reserved.  Atari and the Atari logo are registered trademarks 
                    owned by Atari Interactive, Inc.  All individual Atari game names and logos are trademarks or registered 
                    trademarks of Atari Interactive, Inc. or its affiliates.  Used with permission.  All other trademarks are 
                    properties of their respective owners.`
                    )}
                </p>
            </div>
            <PrivacyPolicyAndTermsOfService
                isOpen={isOpen}
                onClose={() => {
                    setIsOpen(false);
                }}
            />
        </div>
    );
}

export default Footer;
