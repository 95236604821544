import React, { useEffect } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Container } from "react-bootstrap";
import TeaserPage from "./containers/TeaserPage";
import TempPage from "./containers/TempPage";
import AppRoute from "./routes/AppRouteV2";
import Header from "./components/Header";
import AOS from "aos";
import useIntervalRender from "./hooks/useIntervalRender";
import isPast from "./helpers/isPast";
import { LIVE_TIME } from "./config";
import "aos/dist/aos.css";
import "./styles/app.scss";
import "react-sliding-pane/dist/react-sliding-pane.css";

const TeaserEnd = process.env.REACT_APP_TEASER_END;

function App() {
    useIntervalRender();

    useEffect(() => {
        AOS.init({
            duration: 1000,
            once: true
        });
    }, []);

    return (
        <BrowserRouter>
            {!TeaserEnd || (!!TeaserEnd && isPast(TeaserEnd)) ? (
                <AppRoute /> 
            ) : (
                <Container fluid id="teaser-container">
                    <Header />
                    <Switch>
                        <Route exact path="/" component={TeaserPage} />
                    </Switch>
                </Container>
            )}
        </BrowserRouter>
    );
}

export default App;
