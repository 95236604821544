import React from "react";
import { Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import SocialMediaLinks from "./SocialMediaLinks";
import atari from "../static/image/Atari-Logo.png";

function Header(props) {
    return (
        <Navbar id="navbar" className="justify-content-between position-absolute container-fluid align-items-start">
            <Navbar.Brand>
                <Link to="/">
                    <img src={atari} alt="Atari" height="60" />
                </Link>
            </Navbar.Brand>
            {props.rightHeader ? <div className="align-self-right">{props.rightHeader}</div> : null}
        </Navbar>
    );
}

export default Header;
