export const LIVE_TIME = "2021-03-31T04:00:00.000Z";

export const MAIL_TO = "support@myquidd.com";

export const CENTIPEDE_DROP_SCHED = {
    "Centipede Flagship": "2021-04-02T03:00:00.000Z",
    "Centipede Artist's Rendition: Kidpxl": "2021-04-01T00:00:00.000Z",
    "Centipede Artist's Rendition: Amanda Devik": "2021-04-01T00:00:00.000Z",
    "Centipede Reality": "2021-04-01T01:00:00.000Z",
    "Centipede 2600 Cartridge Classic - GOLD": "2021-04-01T01:00:00.000Z",
    "Centipede 2600 Cartridge Classic - Black": "2021-03-30T00:00:00.000Z",
    "Centipede 2600 Cartridge Classic - RED": "2021-04-01T00:00:00.000Z"
};

export const PONG_DROP_SCHED = {
    "Pong Flagship": "2021-04-02T02:00:00.000Z",
    "Pong Artist's Rendition: ARC": "2021-04-02T01:00:00.000Z",
    "Pong Artist's Rendition: Kidpxl": "2021-04-01T00:00:00.000Z",
    "Pong First Quarter Relic": "2021-04-01T01:00:00.000Z",
    "Pong Artist's Rendition: Amanda Devik": "2021-04-01T00:00:00.000Z"
};

export const MAILER_API_KEY = "f32b5842ed236ab30292ad88a87755d6";

export const MAILER_GROUP_ID = "106739398";

export const PROXY_URL = "https://cors-anywhere.herokuapp.com/";
