import React from "react";
import { useTranslation } from "react-i18next";
import ImageButton from "../ImageButton";

function TimelineCard(props) {
    const { t } = useTranslation();

    return (
        <div data-aos={props.right ? "fade-left" : "fade-right"} data-aos-offset="400" className={"timelinecard-container" + (props.right ? " timelinecard-right" : "")}>
            <div className={props.right ? " text-left" : "text-right"}>
                {props.item.month ? <span className="text-large bebas-regular mr-2">{t(props.item.month)}</span> : null}
                <span className="timelinecard-year bebas-regular">{props.item.year}</span>
                <div className="helvetica-neu-light text-small">{t(props.item.desc)}</div>
            </div>
            <ImageButton onClick={props.onClickImage} src={props.item.image} imgClassName="timeline-img" />
        </div>
    );
}

export default TimelineCard;
