import React from "react";
import { useTranslation } from "react-i18next";

function DetailPageHeader(props) {
    const { t } = useTranslation();

    return (
        <div id="detail-page-header">
            <div>
                <div id="detail-page-logo">
                    <img src={props.logo} alt="" />
                    <div className="pixellari-medium">
                        <h5>{t("THE ATARI")}</h5>
                        <h5>{t("CAPSULE COLLECTION")}</h5>
                    </div>
                </div>
                <h4 className="font-weight-normal bebas-regular">{t(props.headerText)}</h4>
            </div>
            <h1 id="detail-page-year" className="bebas-regular">
                {props.year}
            </h1>
        </div>
    );
}

export default DetailPageHeader;
