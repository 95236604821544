import React, { useState } from "react";
import { Form, Button, Spinner } from "react-bootstrap";
import { MAILER_API_KEY, MAILER_GROUP_ID, PROXY_URL } from "../config";

function Signup(props) {
    const [email, setEmail] = useState("");
    const [sending, setSending] = useState(false);
    const [msg, setMsg] = useState("");

    const sendMail = async email => {
        setSending(true);
        try {
            const apiUrl = `https://api.mailerlite.com/api/v2/groups/${MAILER_GROUP_ID}/subscribers`;
            const postData = await fetch(PROXY_URL + apiUrl, {
                method: "POST",
                body: JSON.stringify({ email }),
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "X-MailerLite-ApiKey": MAILER_API_KEY
                }
            });

            setEmail("");
            setMsg("Subscription Successful!");
        } catch (e) {
            console.log(e);
            setMsg("Subscription Failed!");
        }
        setSending(false);
    };

    return (
        <Form
            onSubmit={e => {
                e.preventDefault();
                sendMail(email);
            }}
        >
            <Form.Group id="signup-form-group">
                <div id="email-input-container">
                    <Form.Control
                        type="email"
                        placeholder="email"
                        size="sm"
                        value={email}
                        onChange={e => {
                            setEmail(e.target.value);
                            setMsg("");
                        }}
                    />
                    <p className="text-center no-wrap mt-2 text-small">{msg}</p>
                </div>
                <div id="signup-btn-container">
                    <Button type="submit" className="bebas-regular d-flex text-align-center justify-content-center">
                        SIGN UP
                        {sending ? <Spinner size="sm" animation="border" className="ml-2" /> : null}
                    </Button>
                    <p className="text-xs text-center px-4 no-wrap">
                        By continuing, you agree to accept our{" "}
                        <a
                            href="#"
                            className="text-decoration-none"
                            onClick={e => {
                                e.preventDefault();
                                props.onClickLink();
                            }}
                        >
                            Privacy Policy & Terms of Service
                        </a>
                        .
                    </p>
                </div>
            </Form.Group>
        </Form>
    );
}

export default Signup;
