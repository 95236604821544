import React, { useState } from "react";
import DetailPage from "./DetailPage";
import logo from "../static/image/centipede-page/centipede_logo.png";
import sig from "../static/image/centipede-page/donasig.png";
import tile1Img from "../static/image/centipede-page/centipede_cabinetLarge.png";
import tile2Img from "../static/image/centipede-page/C1.png";
import tile3Img from "../static/image/centipede-page/centipede1981.png";
import tile4Img from "../static/image/centipede-page/centipedereality.png";
import tile5Img from "../static/image/centipede-page/centipedecartridgegold.png";
import tile6Img from "../static/image/centipede-page/Centipede_BlackCart.png";
import tile7Img from "../static/image/centipede-page/centipederedcart.png";
import tile1ImgFull from "../static/image/product-full-view/CentipedeFlagship_Front.png";
import tile2ImgFull from "../static/image/product-full-view/CentipedeArtistsRendition_Kidpxl_BG.png";
import tile3ImgFull from "../static/image/product-full-view/CentipedeArtistsRendition_AD_BG.png";
import tile4ImgFull from "../static/image/product-full-view/CentipedeReality_Green.png";
import tile5ImgFull from "../static/image/product-full-view/CentipedeCartridge_Gold_Front.png";
import tile6ImgFull from "../static/image/product-full-view/CentipedeCartridge_Black_Front.png";
import tile7ImgFull from "../static/image/product-full-view/CentipedeCartridge_Red_Front.png";
import tile1Gif from "../static/gif/centipede_cabinetLarge.gif";
import tile3Gif from "../static/gif/centipede1981_160.gif";
import tile4Gif from "../static/gif/centipede_reality_green_160.gif";
import tile5Gif from "../static/gif/centipede_cartridges_gold_160.gif";
import tile6Gif from "../static/gif/centipede_cartridges_black_160.gif";
import tile7Gif from "../static/gif/centipede_cartridges_Red_160.gif";
import bgImage1 from "../static/image/centipede-page/Interview Image 02.png";
import bgImage2 from "../static/image/centipede-page/centipede-bg-2.png";
import legendImg from "../static/image/centipede-page/donalaiughing.png";
import interviewAudio from "../static/audio/DB_Interview_BookOfIdeas.mp3";
import TileContent1 from "../components/DetailPage/TileContent1";
import TileContent2 from "../components/DetailPage/TileContent2";
import Tile from "../components/DetailPage/Tile";
import centipedeBgImg1 from "../static/image/mushroom-img.png";
import centipedeBgImg3 from "../static/image/centipede-page/darkredmushroom.png";
import HeaderLogo from "../static/image/pong-page/ponglogo.png";
import { Link } from "react-router-dom";
import ImageView from "../components/ImageView";
// import getProdDesc from "../helpers/getProdDesc";
// import { LIVE_TIME, CENTIPEDE_DROP_SCHED } from "../config";
// import isPast from "../helpers/isPast";

function CentipedePage(props) {
    const [imageView, setImageView] = useState(null);

    const tiles = [
        <Tile
            image={tile1Img}
            onImageClick={() => {
                setImageView(tile1ImgFull);
            }}
            gif={tile1Gif}
            content={
                <TileContent1
                    logo={logo}
                    signImg={sig}
                    mainText="3D Coin-Op Cabinet nft, digital Signature by Dona Bailey. Comes with fully 
                    functioning, original coin-op cabinet delivered to the new owner of this one of a kind relic."
                    available="Only 1 available"
                    desc={"ON SALE NOW!"}
                    url="https://blockparty.co/item?id=552"
                />
            }
        />,
        <Tile
            image={tile2Img}
            onImageClick={() => {
                setImageView(tile2ImgFull);
            }}
            content={
                <TileContent2
                    title="Artist’s rendition: kidpxl centipede Retro pixel art"
                    available=""
                    desc={"SOLD OUT"}
                    url="https://blockparty.co/creator/GCPXSRKRDQKFO7FN6DP5THP2QUMFR7UZV3UICQRDQY3U2Y6SWVGKL62W"
                />
            }
        />,
        <Tile
            image={tile3Img}
            onImageClick={() => {
                setImageView(tile3ImgFull);
            }}
            gif={tile3Gif}
            content={
                <TileContent2
                    title="Artist’s rendition: Amanda Devik centipede 1981"
                    available="Only 10 available"
                    desc={"SOLD OUT"}
                    url="https://blockparty.co/creator/GCPXSRKRDQKFO7FN6DP5THP2QUMFR7UZV3UICQRDQY3U2Y6SWVGKL62W"
                />
            }
        />,
        <Tile
            image={tile4Img}
            onImageClick={() => {
                setImageView(tile4ImgFull);
            }}
            gif={tile4Gif}
            content={
                <TileContent2
                    title="Centipede Reality 3D centipede sprite scene"
                    available="Only 3 available"
                    desc={"ON SALE NOW!"}
                    url="https://blockparty.co/creator/GCPXSRKRDQKFO7FN6DP5THP2QUMFR7UZV3UICQRDQY3U2Y6SWVGKL62W"
                />
            }
        />,
        <Tile
            image={tile5Img}
            onImageClick={() => {
                setImageView(tile5ImgFull);
            }}
            gif={tile5Gif}
            content={
                <TileContent2
                    title="Atari 2600 Centipede cartridge 3D retro 2600 cartridge"
                    available="Only 20 available"
                    desc={"ON SALE NOW!"}
                    url="https://blockparty.co/creator/GCPXSRKRDQKFO7FN6DP5THP2QUMFR7UZV3UICQRDQY3U2Y6SWVGKL62W"
                />
            }
        />,
        <Tile
            image={tile6Img}
            onImageClick={() => {
                setImageView(tile6ImgFull);
            }}
            gif={tile6Gif}
            content={
                <TileContent2
                    title="Atari 2600 Centipede cartridge 3D retro 2600 cartridge"
                    available="Only 30 available"
                    desc={"SOLD OUT"}
                    viewOn="opensea"
                    url="https://opensea.io/collection/atari-capsule-collection"
                />
            }
        />,
        <Tile
            image={tile7Img}
            onImageClick={() => {
                setImageView(tile7ImgFull);
            }}
            gif={tile7Gif}
            content={
                <TileContent2
                    title="Atari 2600 Centipede cartridge 3D retro 2600 cartridge"
                    available="Only 20 available"
                    desc={"SOLD OUT"}
                    viewOn="harmony"
                    url="https://atari.harmony.one/"
                />
            }
        />
    ];

    return (
        <>
            <DetailPage
                rightHeader={
                    <Link to="/pong">
                        <img
                            src={HeaderLogo}
                            alt=""
                            className="header-logo"
                            data-aos="zoom-in"
                            data-aos-easing="ease-in-out-back"
                        />
                    </Link>
                }
                logo={logo}
                headerText="Own unique centipede digital relics on the blockchain"
                year="1981"
                tiles={tiles}
                articleTitle="Dona Bailey - The First Woman Programmer In The Coin-Op Department Of Atari"
                articleTexts={[
                    `Dona Bailey was an only child who grew up in Little Rock, Arkansas. She started college at the University of Arkansas at Little Rock by age 16 and graduated by age 19 with a bachelor’s in Psychology. After earning a master’s degree in Math, Dona was hired by GM in 1978 to work on 6502 assembly language programming engine function controls. Her first introduction to arcade games was when she heard the song “Space Invaders” by The Pretenders at a friends record shop. Instead of simply explaining the arcade game, her friend took her to a local arcade to experience the actual game and history was written. Dona knew she wanted to work with video games so she quit her job at GM, moved out to California, and was determined to work at Atari.`,

                    `In 1980, Dona was hired at Atari to be the first woman programmer in the coin-op department. Her first assignment was on the small team that would end up creating arcade Centipede. While searching through the Atari idea notebook, Dona came across an entry titled “Centipede” described as “A multi segmented insect crawls on to the screen and is shot by the player.” This unique idea appealed to her because it resembled Galaga, which was her favorite game at the time. As the software developer and software engineer, Dona started from the ground up, figuring out ways to design characters and fit the program within limited PROM chips.`,

                    `Centipede was released in 1981 and quickly became Atari’s second best-selling coin operated game. The game’s vibrant pastel colors and track-ball appealed to both male and female players which helped it to become one of the first coin-op games to become popular with a significant female player base. In the words of Dona Bailey, “Centipede was as close to alchemy as I’ll ever achieve.”`
                ]}
                bgElement1={{
                    image: bgImage1,
                    text:
                        "“The idea for Centipede originally came from a book of game ideas at Atari, can you tell us more about the process”?",
                    audio: interviewAudio
                }}
                bgElement2={{ image: bgImage2 }}
                legendImg={legendImg}
                legendFact="My first hand held electronic device growing up was a transistor radio."
                tileBgElement={
                    <>
                        <img
                            src={centipedeBgImg1}
                            alt=""
                            id="centipede-bg-img-1"
                        />
                        <img
                            src={centipedeBgImg1}
                            alt=""
                            id="centipede-bg-img-2"
                        />
                        <img
                            src={centipedeBgImg3}
                            alt=""
                            id="centipede-bg-img-3"
                        />
                    </>
                }
            />
            <ImageView src={imageView} onHide={() => setImageView(null)} />
        </>
    );
}

export default CentipedePage;
