var audio = new Audio();

export const play = file => {
    audio.src = file;
    audio.play();
};

export const pause = () => {
    audio.pause();
};
