import React, { useState } from "react";

function Tile(props) {
    const [imgHover, setImgHover] = useState(false);

    return (
        <div className="tile" onMouseEnter={() => setImgHover(true)} onMouseLeave={() => setImgHover(false)}>
            <div className="tile-img-container" onClick={props.onImageClick}>
                <img className={"tile-img " + props.imgClass} src={imgHover && props.gif ? props.gif : props.image} />
            </div>
            <div className="tile-content">{props.content}</div>
            {/* {props.CommingSoon ? (
                <div className="comming-soon">
                    <span className="text-large text-white bebas-regular">Coming Soon</span>
                </div>
            ) : null} */}
        </div>
    );
}

export default Tile;
