import image1 from "../static/image/landing-page-2/1971.png";
import image2 from "../static/image/landing-page-2/May1972.png";
import image3 from "../static/image/landing-page-2/Nov1972.png";
import image4 from "../static/image/landing-page-2/Sept1977.png";
import image5 from "../static/image/landing-page-2/donabaileyportrait.png";
import image6 from "../static/image/landing-page-2/1981.png";
import image7 from "../static/image/landing-page-2/AlAlcornportrait.png";
import image8 from "../static/image/landing-page-2/dona1985.png";
import audio1 from "../static/audio/computerspace.mp3";
import audio2 from "../static/audio/AA_HeyNolan.mp3";
import audio3 from "../static/audio/AA_ChangedTheWorld.mp3";
import audio4 from "../static/audio/Pong_Arcade_DoubleDeflect.mp3";
import audio5 from "../static/audio/DB_ImDonaBailey.mp3";
import audio6 from "../static/audio/DB_Pioneer.mp3";
import audio7 from "../static/audio/AA_IfYouCantFixIt.mp3";
import audio8 from "../static/audio/DB_HistoryUntold.mp3";

const timeline = [
    {
        image: image1,
        audio: audio1,
        year: 1971,
        desc: `Al Alcorn was working for Ampex designing video circuits, Al heard that Nolan Bushnell and Ted Dabney were working on a game called Computer Space for Nutting Associates.`
    },
    {
        image: image2,
        audio: audio2,
        month: "MAY",
        year: 1972,
        desc: `In May of 1972, Al had a lunch meeting with Nolan Bushnell and Ted Dabney where they convinced him to join them at their new company, Syzygy,  Al was the third employee at at Syzygy, which was officially incorporated as Atari Inc. in June, 1972.`
    },
    {
        image: image3,
        audio: audio3,
        month: "NOV",
        year: 1972,
        desc: `Al was told Atari had a contract with GE to design a game based on ping pong, He managed to come up with a prototype within three months. Ironically, the game played so well, Nolan decided to get the game out to the public to see what it did. There was no contract with GE, Pong was initially given as a test.`
    },
    {
        image: image4,
        audio: audio4,
        month: "SEPT",
        year: 1977,
        desc: `Pong home was launched September, 1977 at a price of $199 USD (roughly $859 USD in 2021). It included the system with all hookups, 2 of the now classic controllers, and a game cart labeled Combat. The VCS sold 250,000 units in 1977, but the world was Pong-ed out from the Pong Console race and the VCS sales were not fantastic, as only 550,000 units were sold in 1978 out of the 800,000 that were produced.`
    },
    {
        image: image5,
        audio: audio5,
        month: "JUN",
        year: 1980,
        desc: `After grueling interviews at Atari, Dona was hired fairly quickly in June of 1980. Two weeks into the job, it was suggested to Dona that she find a game idea in the Atari game development notebook. In this notebook, One idea stood out to Dona, titled “Centipede”.  The only description was “A multi segmented insect crawls on to the screen and is shot by the player,” `
    },
    {
        image: image6,
        audio: audio6,
        year: 1981,
        desc: `The game went on to sell just over 54,000 units, and became Atari’s second best selling coin-op of all time. The game was reported as popular with a strong female base giving Atari an edge with that growing demographic of arcade gamers.`
    },
    {
        image: image7,
        audio: audio7,
        year: 1981,
        desc: `Al leaves Atari by 1981 after developing a revolutionary gaming system called the Cosmos. Despite considerable effort invested into the project by Al and his team, Atari management at the time refuses to commit to manufacturing 250,000 Cosmos units on order. `
    },
    {
        image: image8,
        audio: audio8,
        year: 1982,
        desc: `Dona left Atari in 1982 and joined a startup company called Videa, founded by 3 former Atari employees. After Videa, Dona worked at Activision, but ultimately decided to leave the video game industry by 1985. `
    }
];

export default timeline;
