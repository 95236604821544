import React from "react";
import quiddLogo from "../static/image/quidd_logo.png";
import blockPartyLogo from "../static/image/blockparty_logo.png";

function QuiddXBlockParty(props) {
    var style = { img: {}, x: {} };
    if (props.size === "sm") {
        style.img["height"] = "24px";
        style.img["margin"] = "0 10px";
        style.x["fontSize"] = "14px";
    }

    return (
        <div id="quidd-x-blockparty">
            <img src={quiddLogo} style={{ ...style.img }} />
            <span className="text-large" style={{ ...style.x }}>
                X
            </span>
            <img src={blockPartyLogo} style={{ ...style.img }} />
        </div>
    );
}

export default QuiddXBlockParty;
