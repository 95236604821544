import React from "react";
import quidd from "../static/image/teaser-page/quiddlogo.png";
import blockparty from "../static/image/teaser-page/blockpartylogo.png";
import opensea from "../static/image/teaser-page/opensealogo.png";
import animocaBrands from "../static/image/teaser-page/animocabrandslogo.png";
import atari from "../static/image/teaser-page/atarilogowhite.png";
import harmony from "../static/image/teaser-page/HarmonyLogo.png";

function BrandsLinks(props) {
    return (
        <div id="brands-container">
            <a href="https://market.onquidd.com/" target="_blank">
                <img src={quidd} />
            </a>
            <a href="https://blockparty.co/" target="_blank">
                <img src={blockparty} />
            </a>
            <a href="https://opensea.io/" target="_blank">
                <img src={opensea} />
            </a>
            <a href="https://www.animocabrands.com/" target="_blank">
                <img src={animocaBrands} />
            </a>
            <a href="https://www.atari.com/" target="_blank">
                <img src={atari} />
            </a>
            <a href="https://www.harmony.one/">
                <img src={harmony} />
            </a>
        </div>
    );
}

export default BrandsLinks;
