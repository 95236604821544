import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Timeline from "../components/Timeline/Timeline";
import CentipedeCabinet from "../static/image/landing-page-2/CentipedeCabinetDark.png";
import PongCabinet from "../static/image/landing-page-2/PongCabinetDark.png";
import CentipedeLogo from "../static/image/landing-page-2/centipedecabinetlogo.png";
import PongLogo from "../static/image/landing-page-2/pongcabinetlogo.png";
import RedHaze from "../static/image/landing-page-2/redhaze.png";
import QuiddXBlockParty from "../components/QuiddXBlockParty";
import Header from "../components/Header";
import timeline from "../timeline/timeline";
import "../styles/landingPage.scss";

export default () => {
    const { t } = useTranslation();

    return (
        <Container fluid className="px-0">
            <Header />
            <div id="hero-container">
                <div id="hero-content">
                    <div id="hero-title-container">
                        <div id="hero-title-content">
                            <h1 className="bebas-regular">{t("OWN A PIECE OF")}</h1>
                            <span className="bebas-regular" id="history">
                                {t("HISTORY")}
                            </span>
                            <div id="capsule-title" className="pixellari-medium">
                                <span>{t("THE ATARI")}</span>
                                <span>{t("CAPSULE COLLECTION")}</span>
                            </div>
                        </div>

                        <div className="text-left">
                            <QuiddXBlockParty size="sm" />
                        </div>
                        <div id="hero-text-container">
                            <p className="text-small">
                                {t(
                                    `In 1972, Nolan Bushnell and Ted Dabney spawned one of the most iconic companies in American history.  Starting with a $700 of investement capital, the pair started Syzygy with the intent of developing a Spacewar computer game clone called Computer Space. The company name Syzygy was changed to Atari in June of 1972. Atari went on to pioneer the coin-op arcade industry, as well as in home entertainment, attracting the likes of legends Al Alcorn, who created Pong, and Dona Bailey who programmed Centipede.`
                                )}
                            </p>
                        </div>
                    </div>
                    <div id="cabinet-container">
                        <img src={RedHaze} id="red-haze" alt="" />
                        <Link to="/centipede" id="centipede-cabinet">
                            <div id="centipede-cabinet-logo">
                                <img src={CentipedeLogo} alt="" />
                            </div>
                            <img src={CentipedeCabinet} className="cabinet-hidden" alt="" />
                        </Link>
                        <Link to="/pong" id="pong-cabinet">
                            <div id="pong-cabinet-logo">
                                <img src={PongLogo} alt="" />
                            </div>
                            <img src={PongCabinet} className="cabinet-hidden" alt="" />
                        </Link>
                    </div>
                </div>
            </div>
            <Timeline title="TIMELINE" items={timeline} hideLine />
        </Container>
    );
};
