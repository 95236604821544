import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import DetailPageHeader from "../components/DetailPage/DetailPageHeader";
import TilesContainer from "../components/DetailPage/TilesContainer";
import { play, pause } from "../helpers/audio";
import ImageButton from "../components/ImageButton";
import speaker from "../static/image/speakerwhite.png";
import factBubble from "../static/image/factbubble.png";
import Header from "../components/Header";
import "../styles/detailPage2.scss";

function DetailPage(props) {
    const [audioStatus, setAudioStatus] = useState("PAUSED");
    const { t } = useTranslation();

    const handleAudioPlay = () => {
        if (audioStatus === "PAUSED") {
            play(props.bgElement1.audio);
            setAudioStatus("PLAYING");
        } else if (audioStatus === "PLAYING") {
            pause(props.bgElement1.audio);
            setAudioStatus("PAUSED");
        }
    };

    return (
        <div>
            <Header rightHeader={props.rightHeader} />
            <div id="detail-page">
                <TilesContainer tiles={props.tiles} tileBgElement={props.tileBgElement} header={
                    <DetailPageHeader logo={props.logo} headerText={props.headerText} year={props.year} />
                }/>
                <div className="detail-page-body">
                    <div className="article-container">
                        <div className="bg-element bg-element-1">
                            <div className="pr-3">
                                <img src={props.bgElement1.image} alt="" data-aos="zoom-in" data-aos-easing="ease-in-out-back" />
                            </div>
                            <span className="bebas-regular bebas-sm-spacing" data-aos="fade">
                                {t(props.bgElement1.text)}
                                <ImageButton src={speaker} onClick={handleAudioPlay} imgClassName="audio-control" />
                            </span>
                        </div>
                        <div>
                            <h3 className="bebas-regular mb-4">{t(props.articleTitle)}</h3>
                            <div>
                                {props.articleTexts.map((text, i) => (
                                    <p key={i}>{t(text)}</p>
                                ))}
                            </div>
                        </div>
                        <div className="bg-element bg-element-2">
                            <img src={props.bgElement2.image} alt="" data-aos="zoom-in" data-aos-easing="ease-in-out-back" />
                        </div>
                    </div>

                    <div className="legend-container" id="legend-container">
                        <div className="fact-bubble" data-aos="fade" data-aos-anchor="#legend-container">
                            <img src={factBubble} alt="" />
                            <span className="bebas-regular fact-text bebas-sm-spacing">{t(props.legendFact)}</span>
                        </div>
                        <img src={props.legendImg} alt="" className="legend-img" data-aos="zoom-in" data-aos-easing="ease-in-out-back" data-aos-anchor="#legend-container" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DetailPage;
