import { useState, useEffect } from "react";
import moment from 'moment';

const useIntervalRender = (interval = 1000) => {
    const [currentTime, setCurrentTime] = useState(moment());

    useEffect(() => {
        let timer = setInterval(() => {
            setCurrentTime(moment());
        }, interval);

        return () => {
            clearInterval(timer);
        };
    }, []);
}

export default useIntervalRender;