import React from "react";

import Title from "./Title";
import TimelineList from "./TimelineList";

function Timeline(props) {
    return (
        <div id="timeline-container">
            <Title title={props.title} />
            <div className="text-center">
                <TimelineList items={props.items} hideLine={props.hideLine} />
            </div>
        </div>
    );
}

export default Timeline;
