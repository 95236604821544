import React, { useState } from "react";
import DetailPage from "./DetailPage";
import logo from "../static/image/pong-page/ponglogo.png";
import sig from "../static/image/pong-page/alcornsig.png";
import tile1Img from "../static/image/pong-page/pong_cabinetLarge.png";
import tile2Img from "../static/image/pong-page/arc.png";
import tile3Img from "../static/image/pong-page/pongkidpxl.png";
import tile4Img from "../static/image/pong-page/PongQuarter.png";
import tile5Img from "../static/image/pong-page/PONG1972.png";
import tile1ImgFull from "../static/image/product-full-view/PongFlagship_Front.png";
import tile2ImgFull from "../static/image/product-full-view/PongArtistsRendition_Arc_BG.png";
import tile3ImgFull from "../static/image/product-full-view/PongArtistsRendition_KidPxl_BG.png";
import tile4ImgFull from "../static/image/product-full-view/PongQuarter_Front.png";
import tile5ImgFull from "../static/image/product-full-view/PongArtistsRendition_AD_BG.png";
import tile1Gif from "../static/gif/pong_cabinetLarge.gif";
import tile2Gif from "../static/gif/pong_Arc.gif";
import tile3Gif from "../static/gif/pong_KidPxl.gif";
import tile4Gif from "../static/gif/pongQuater160.gif";
import tile5Gif from "../static/gif/pong1972_160.gif";
import bgImage1 from "../static/image/pong-page/pong-quarter.png";
import bgImage2 from "../static/image/pong-page/pong-pad.png";
import legendImg from "../static/image/pong-page/AlAlcornportrait.png";
import interviewAudio from "../static/audio/AA_Interview_MobRoute_small.mp3";
import TileContent1 from "../components/DetailPage/TileContent1";
import TileContent2 from "../components/DetailPage/TileContent2";
import Tile from "../components/DetailPage/Tile";
import pongBgImg1 from "../static/image/pong-page/pongractangle1.png";
import pongBgImg2 from "../static/image/pong-page/pongrectangle2.png";
import pongBgImg3 from "../static/image/pong-page/pongsquare.png";
import HeaderLogo from "../static/image/centipede-page/centipede_logo.png";
import { Link } from "react-router-dom";
import ImageView from "../components/ImageView";
// import getProdDesc from "../helpers/getProdDesc";
// import { LIVE_TIME, PONG_DROP_SCHED } from "../config";
// import isPast from "../helpers/isPast";

function PongPage(props) {
    const [imageView, setImageView] = useState(null);

    const tiles = [
        <Tile
            image={tile1Img}
            onImageClick={() => {
                setImageView(tile1ImgFull);
            }}
            gif={tile1Gif}
            content={
                <TileContent1
                    logo={logo}
                    signImg={sig}
                    mainText="3D Coin-Op Cabinet nft, digital Signature by al Alcorn"
                    available="Only 1 available"
                    desc={"ON SALE NOW!"}
                    url="https://blockparty.co/item?id=556"
                />
            }
        />,
        <Tile
            image={tile2Img}
            onImageClick={() => {
                setImageView(tile2ImgFull);
            }}
            gif={tile2Gif}
            content={
                <TileContent2
                    title="Artist’s Rendition Cute arcade by Arc"
                    available="Only 1 available"
                    desc={"ON SALE NOW!"}
                    url="https://blockparty.co/creator/GCPXSRKRDQKFO7FN6DP5THP2QUMFR7UZV3UICQRDQY3U2Y6SWVGKL62W"
                />
            }
        />,
        <Tile
            image={tile3Img}
            onImageClick={() => {
                setImageView(tile3ImgFull);
            }}
            gif={tile3Gif}
            content={
                <TileContent2
                    title="Artist’s rendition: kidpxl pong Retro pixel art"
                    available="Only 10 available"
                    desc={"ON SALE NOW!"}
                    url="https://blockparty.co/creator/GCPXSRKRDQKFO7FN6DP5THP2QUMFR7UZV3UICQRDQY3U2Y6SWVGKL62W"
                />
            }
        />,
        <Tile
            image={tile4Img}
            onImageClick={() => {
                setImageView(tile4ImgFull);
            }}
            gif={tile4Gif}
            content={
                <TileContent2
                    title="Pong first quarter relic"
                    available="Only 3 available"
                    desc={"ON SALE NOW!"}
                    url="https://blockparty.co/creator/GCPXSRKRDQKFO7FN6DP5THP2QUMFR7UZV3UICQRDQY3U2Y6SWVGKL62W"
                />
            }
        />,
        <Tile
            image={tile5Img}
            onImageClick={() => {
                setImageView(tile5ImgFull);
            }}
            gif={tile5Gif}
            content={
                <TileContent2
                    title="Artist’s rendition: Amanda Devik pong 1981"
                    available="Only 10 available"
                    desc={"ON SALE NOW!"}
                    url="https://blockparty.co/creator/GCPXSRKRDQKFO7FN6DP5THP2QUMFR7UZV3UICQRDQY3U2Y6SWVGKL62W"
                />
            }
        />
    ];

    return (
        <>
            <DetailPage
                rightHeader={
                    <Link to="/centipede">
                        <img
                            src={HeaderLogo}
                            className="header-logo"
                            data-aos="zoom-in"
                            data-aos-easing="ease-in-out-back"
                        />
                    </Link>
                }
                logo={logo}
                headerText="Own unique pong digital relics on the blockchain"
                year="1972"
                tiles={tiles}
                articleTitle="Al Alcorn - Responsible For Creating The First Commercially Successful Arcade Game"
                articleTexts={[
                    `Al Alcorn grew up in San Francisco, California and graduated from the University of California, Berkeley in 1971. With his Bachelor’s of Science in Electrical Engineering and Computer Sciences, Al worked for the California-based video technology company Ampex. There he met Ted Dabney and Nolan Bushnell.`,

                    `In 1971, Dabney and Bushnell formed Syzygy Co. which would go on to be called Atari.  In 1972, with no video game experience, Al joined the company as employee number three. To test his abilities, Bushnell claimed they had a contract with GE to design a game based on table tennis. According to Al, it was the “simplest game you could think of. One spot. Two paddles. Score.” Feeling that the game was a bit too boring he added several features to make it more appealing. He divided the paddle into more segments to change the angle of the ball’s return, made the ball speed up the longer it was in play. Instead of realistic sound effects originally requested by Dabney and Bushnell, Al discovered he can generate tones with a sync generator allowing him to save space on the logic board. He also decided to leave in a defect where the in-game paddles didn’t reach the top of the screen. It gave the game more difficulty and it gave Alcorn a new motto, “If you can’t fix it, call it a feature!”`,

                    `In September 1972, the Pong prototype was installed into a local bar named Andy Capp’s Tavern. After a few days, Alcorn was called in to fix the “broken” machine, but it turns out it was just overflowing with quarters. This account has been disputed as not entirely true, according to a long time Bushnell associate, but remained an integral part of the myth behind the Atari legend.  After distribution started expanding beyond California, Pong took off nationwide and by 1972 they had sold over 8,000 units. At its peak, Bushnell touted up to 35,000 Pong machines in the United States.`,

                    `Pong was the first commercially successful arcade game that paved the way for a multi-billion dollar video game industry. In the words of Al Alcorn, “If we did nothing else we changed the world.”`
                ]}
                bgElement1={{
                    image: bgImage1,
                    text:
                        "“Can you tell us about a harrowing incident You once had at a coin-op convention”?",
                    audio: interviewAudio
                }}
                bgElement2={{ image: bgImage2 }}
                legendImg={legendImg}
                legendFact="In high school I played All-City football with O.J. Simpson"
                tileBgElement={
                    <>
                        <img src={pongBgImg1} alt="" id="pong-bg-img-1" />
                        <img src={pongBgImg2} alt="" id="pong-bg-img-2" />
                        <img src={pongBgImg3} alt="" id="pong-bg-img-3" />
                        <img src={pongBgImg1} alt="" id="pong-bg-img-4" />
                    </>
                }
            />
            <ImageView src={imageView} onHide={() => setImageView(null)} />
        </>
    );
}

export default PongPage;
