import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Countdown from "react-countdown";
import { Form, Button } from "react-bootstrap";
import TeaserFooter from "../components/TeaserFooter";
import PrivacyPolicyAndTermsOfService from "../components/PrivacyPolicyAndTermsOfService";
import QuiddXBlockParty from "../components/QuiddXBlockParty";
import "../styles/teaserPage.scss";

function TeaserPage(props) {
    const [isOpen, setIsOpen] = useState(false);
    const { t } = useTranslation();

    return (
        <div id="teaser-page">
            <div id="teaser-content-container">
                <div id="teaser-content">
                    <div data-aos="fade-down">
                        <div>
                            <span className="text-medium">{t("THE ATARI CAPSULE COLLECTION")}</span>
                            <h1>{t("OWN A PIECE OF HISTORY")}</h1>
                        </div>

                        <div id="logo-container">
                            <span>{t("Brought to you by")}</span>
                            <QuiddXBlockParty />
                        </div>
                    </div>

                    <div id="drops-in-container">
                        <span>{t("DROPS IN: ")}</span>
                        <span className="drops-in-count">
                            <Countdown date={process.env.REACT_APP_TEASER_END || "0"} />
                        </span>
                    </div>

                    <p className="text-small">
                        {t(`Lorem ipsum dolor sit amet consectetur adipisicing elit.
                            Eligendi atque ullam quam iusto voluptatibus nesciunt,
                            aliquid libero, numquam reprehenderit dolorem asperiores
                            eveniet molestiae repellat quidem veniam veritatis.
                            Voluptates, rerum repellat. Lorem ipsum dolor sit amet
                            consectetur adipisicing elit. Eligendi atque ullam quam
                            iusto voluptatibus nesciunt, aliquid libero, numquam
                            reprehenderit dolorem asperiores eveniet molestiae
                            repellat quidem veniam veritatis. Voluptates, rerum
                            repellat.`)}
                    </p>

                    <div className="mt-4">
                        <span className="text-small">{t("Sign Up for Alerts")}</span>
                        <div className="d-flex" id="teaser-signup-form">
                            <Form.Control type="email" placeholder={t("EMAIL")} />
                            <Button>{t("SIGN UP")}</Button>
                        </div>
                        <p className="text-xs mt-2" id="policy-and-terms-label">
                            {t(`By signing up, you agree to accept our `)}
                            <a
                                href="#"
                                id="policy-and-terms-link"
                                onClick={e => {
                                    e.preventDefault();
                                    setIsOpen(true);
                                }}
                            >
                                {t("Privacy Policy & Terms of Service")}
                            </a>
                            .
                        </p>
                    </div>
                </div>

                <div id="machine-img-container" />
            </div>

            <TeaserFooter />
            <PrivacyPolicyAndTermsOfService
                isOpen={isOpen}
                onClose={() => {
                    setIsOpen(false);
                }}
            />
        </div>
    );
}

export default TeaserPage;
