import React from "react";
import { Switch, Route } from "react-router-dom";
import { Container } from "react-bootstrap";
import LandingPage from "../containers/LandingPage2";
import CentipedePage from "../containers/CentipedePage";
import PongPage from "../containers/PongPage";
import Footer from "../components/Footer2";

function AppRoute() {
    return (
        <Container fluid id="app-container-2">
            <div id="atari-watermark-bg">
                <div className="red-haze" />
                <div id="bottom-bg">
                    <Switch>
                        <Route exact path="/centipede" component={CentipedePage} />
                        <Route exact path="/pong" component={PongPage} />
                        <Route exact path="/" component={LandingPage} />
                    </Switch>
                </div>
            </div>
            <Footer />
        </Container>
    );
}

export default AppRoute;
