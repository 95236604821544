import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faFacebookF,
    faTwitter,
    faPinterestP,
    faInstagram
} from "@fortawesome/free-brands-svg-icons";

export default function(props) {
    return (
        <div className="d-flex">
            {socialMediaLinks.map((link, i) => (
                <a key={i} href={link.route} className="mx-2">
                    <FontAwesomeIcon
                        icon={link.icon}
                        color="#9b9b9b"
                        size="lg"
                    />
                </a>
            ))}
        </div>
    );
}

const socialMediaLinks = [
    { icon: faFacebookF, route: "#" },
    { icon: faTwitter, route: "#" },
    { icon: faPinterestP, route: "#" },
    { icon: faInstagram, route: "#" }
];
