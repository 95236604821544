import React from "react";
import blockpartyLogo from "../../static/image/blockparty_logo.png";
import openseaLogo from "../../static/image/opensealogo.png";
import harmonyLogo from "../../static/image/Harmony.png";

const viewOn = {
    blockparty: {
        logo: blockpartyLogo
    },
    opensea: {
        logo: openseaLogo
    },
    harmony: {
        logo: harmonyLogo
    }
};

function ViewOnButton(props) {
    return (
        <button
            className={"bebas-regular view-on-btn bebas-sm-spacing " + props.btnClass}
            onClick={() => {
                if (props.url) {
                    window.open(props.url);
                }
            }}
        >
            VIEW ON
            <img src={viewOn[props.viewOn || "blockparty"].logo} />
        </button>
    );
}

export default ViewOnButton;
