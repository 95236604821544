import React from "react";

function TilesContainer(props) {
    return (
        <div className="d-flex justify-content-center">
            <div>
                {props.header}
                <div className="tile-wrapper">
                    {props.tileBgElement}
                    {props.tiles.map((tile, i) => (
                        <div key={i} className={"tile-item tile-item-" + (i + 1)}>
                            {tile}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default TilesContainer;
