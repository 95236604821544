import React from "react";
import SlidingPane from "react-sliding-pane";
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

const PrivacyPolicyAndTermsOfService = props => {
    return (
        <SlidingPane className="privacy-policy" overlayClassName="" isOpen={props.isOpen} from="bottom" hideHeader onRequestClose={props.onClose}>
            <Container fluid className="helvetica-neu-medium">
                <Row className="my-3">
                    <Col md={12}>
                        <button id="privacy-policy-close" className="hide-btn" onClick={props.onClose}>
                            <FontAwesomeIcon icon={faTimesCircle} />
                        </button>
                        <h4>Privacy Policy and Terms of service</h4>
                        <h5>Quidd Terms of Use</h5>
                        <p>
                            Quidd, Inc. (“Quidd,” “we,” “us,” or “our“) welcomes you. We’re excited that you have decided to access and use our website, www.quiddcapsulecollection.com or ataricapsulecollection.com (the
                            “Website”)
                        </p>
                        <p>
                            Please read these Terms of Use carefully. Quidd provides the the website to you subject to the following Terms of Use, which may be updated by us from time to time. Please be sure that you
                            have read, understood, and agree to be legally bound by the terms and conditions of these Terms of Use and the terms and conditions of Quidd’s Privacy Policy, which is hereby incorporated by
                            reference (collectively, this “Agreement”) and you represent and warrant that you are at least 13 years old or older. Subject to some restrictions based on your jurisdiction of residence if
                            you are under age 18 but above 13 you are permitted to use the website, provided you do so with the consent of a parent or legal guardian who accepts the Agreement on your behalf. If you do
                            not agree to any of these terms, then please do not use the website.
                        </p>
                        <h6>Community Guidelines</h6>
                        <p>By accessing and/or using this website, you hereby agree that:</p>
                        <p>
                            You will not use the website for any unlawful purpose;
                            <br />
                            All information provided must be truthful and accurate, and you have a duty to update any information previously provided in order to keep it truthful and accurate;
                            <br />
                            You will not upload, post, e-mail, transmit, or otherwise make available any content that:
                            <br />
                            -infringes any copyright, trademark, right of publicity, or other proprietary rights of any person or entity; or
                            <br />
                            -is threatening, tortious, defamatory, libelous, indecent, obscene, pornographic, sexually explicit, invasive of another’s privacy, or promotes extreme violence or cruelty to animals, or
                            contains hate speech (i.e., speech that attacks or demeans a group based on race or ethnic origin, religion, disability, gender, age, veteran status, and/or sexual orientation/gender
                            identity); or
                            <br />
                            -discloses any sensitive information about another person, including that person’s e-mail address, postal address, phone number, credit card information, or any similar information.
                            <br />
                            You will not impersonate any person or entity or falsely state or otherwise misrepresent your affiliation with a person or entity; and
                            <br />
                            You will not interfere with or attempt to interrupt the proper operation of the website through the use of any virus, device, information collection or transmission mechanism, software or
                            routine, or access or attempt to gain access to any data, files, or passwords related to the website through hacking, password or data mining, or any other means.
                        </p>
                        <p>
                            Unsolicited Information
                            <br />
                            By submitting any unsolicited information and materials, including comments, ideas, questions, designs, and other similar communications (collectively, “Unsolicited Information“) to us, you
                            agree to be bound by the following terms and conditions. If you do not agree with these terms and conditions, you should not provide us with any Unsolicited Information. All Unsolicited
                            Information will be considered NON-CONFIDENTIAL and NON-PROPRIETARY. We, or any of our affiliates, may use such communication or material for any purpose (so long as it does not violate legal,
                            contractual or other obligations of Quidd), including, but not limited to, reproduction, disclosure, transmission, publication, broadcast, and further posting. Further, we and our affiliates
                            are free to use any ideas, concepts, know-how, or techniques contained in any communication or material you send to us for any purpose whatsoever, including, but not limited to, developing,
                            manufacturing, and marketing products. By submitting any Unsolicited Information, you are granting us a perpetual, royalty-free and irrevocable right and license to use, reproduce, modify,
                            adapt, publish, translate, distribute, transmit, publicly display, publicly perform, sublicense, create derivative works from, transfer and sell such Unsolicited Information and to use your
                            name and other identifying information in connection with such Unsolicited Information.
                        </p>
                        <h6>Accessing and Downloading the Quidd App from iTunes</h6>
                        <p>The following terms apply to any Apple App. These terms are in addition to all other terms contained in these Terms of Use:</p>
                        <p>
                            You acknowledge and agree that (i) these Terms of Use are concluded between you and Quidd only, and not Apple, and (ii) Quidd, not Apple, is solely responsible for the Apple App and content
                            thereof. Your use of the Apple App must comply with the App Store Terms of Service.
                            <br />
                            You acknowledge that Apple has no obligation whatsoever to furnish any maintenance and support services with respect to the Apple App.
                            <br />
                            In the event of any failure of the Apple App to conform to any applicable warranty, you may notify Apple, and Apple will refund the purchase price for the Apple App to you and to the maximum
                            extent permitted by applicable law, Apple will have no other warranty obligation whatsoever with respect to the Apple App. As between Quidd and Apple, any other claims, losses, liabilities,
                            damages, costs or expenses attributable to any failure to conform to any warranty will be the sole responsibility of Quidd.
                            <br />
                            You acknowledge that, as between Quidd and Apple, Apple is not responsible for addressing any claims you have or any claims of any third party relating to the Apple App or your possession and
                            use of the Apple App, including, but not limited to: (i) product liability claims; (ii) any claim that the Apple App fails to conform to any applicable legal or regulatory requirement; and
                            (iii) claims arising under consumer protection or similar legislation.
                            <br />
                            You acknowledge that, in the event of any third party claim that the Apple App or your possession and use of that Apple App infringes that third party’s intellectual property rights, as
                            between Quidd and Apple, Quidd, not Apple, will be solely responsible for the investigation, defense, settlement and discharge of any such intellectual property infringement claim to the
                            extent required by these Terms of Use.
                            <br />
                            You represent and warrant that (i) you are not located in a country that is subject to a U.S. Government embargo, or that has been designated by the U.S. Government as a “terrorist supporting”
                            country; and (ii) you are not listed on any U.S. Government list of prohibited or restricted parties.
                            <br />
                            You acknowledge and agree that Apple, and Apple’s subsidiaries, are third party beneficiaries of these Terms of Use as related to your license of the Apple App, and that, upon your acceptance
                            of the terms and conditions of these Terms of Use, Apple will have the right (and will be deemed to have accepted the right) to enforce these Terms of Use as related to your license of the
                            Apple App against you as a third party beneficiary thereof.
                            <br />
                            Without limiting any other terms of these Terms of Use, you must comply with all applicable third party terms of agreement when using the Apple App.
                            <br />
                            Quidd Disclaimers and Limitation of Liability
                        </p>
                        <p>
                            THE APP AND ALL CONTENT, FUNCTIONS AND MATERIALS MADE AVAILABLE TO YOU THROUGH THE APP, IS PROVIDED “AS IS,” “AS AVAILABLE”, WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING,
                            WITHOUT LIMITATION, ANY WARRANTIES OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. NONE OF QUIDD, ITS AFFILIATES, SUBSIDIARIES, OR ITS OR THEIR OFFICERS,
                            DIRECTORS, EMPLOYEES OR AGENTS (COLLECTIVELY THE “QUIDD PARTIES”) WARRANT THAT ANY OF THE FOREGOING WILL BE TIMELY, SECURE, UNINTERRUPTED OR ERROR FREE, OR THAT DEFECTS WILL BE CORRECTED. NONE
                            OF THE QUIDD PARTIES SHALL BE LIABLE FOR ANY DAMAGES TO, OR VIRUSES THAT MAY INFECT, YOUR COMPUTER EQUIPMENT OR OTHER PROPERTY ON ACCOUNT OF YOUR ACCESS TO OR USE OF ANY OF THE FOREGOING. YOUR
                            SOLE AND EXCLUSIVE REMEDY FOR DISSATISFACTION WITH THE APP IS TO STOP USING THE APP.
                        </p>
                        <p>
                            NONE OF THE QUIDD PARTIES ENDORSE OR RECOMMEND ANY USER, USER GENERATED CONTENT OR ANY THIRD PARTY PROVIDER. NONE OF THE QUIDD PARTIES HAS ANY RESPONSIBILITY OR LIABILITY WITH RESPECT TO, ANY
                            USER GENERATED CONTENT THAT YOU PROVIDE, ANY TRANSACTION, COMMUNICATION OR INTERACTION BETWEEN YOU AND THIRD PARTY PROVIDERS OR OTHER USERS OF THE APP OR FOR ANY RESULTS CAUSED BY USING THE
                            APP, INCLUDING WITHOUT LIMITATION, ANY DEATH, OR BODILY INJURY YOU MAY SUFFER, AND ANY MISUSE OF YOUR USER GENERATED CONTENT. NONE OF THE QUIDD PARTIES IS RESPONSIBLE FOR THE CONDUCT, WHETHER
                            ONLINE OR OFFLINE, OF ANY USER OR THIRD PARTY PROVIDER. WE EXPRESSLY DISCLAIM ANY AND ALL LIABILITY IN CONNECTION WITH THE FOREGOING.
                        </p>
                        <p>
                            IN NO EVENT SHALL ANY OF THE QUIDD PARTIES BE LIABLE FOR SPECIAL, INDIRECT, PUNITIVE, EXEMPLARY, OR CONSEQUENTIAL DAMAGES, INCLUDING LOST PROFITS, REVENUES OR SAVINGS, EVEN IF A QUIDD PARTY
                            HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES IN ADVANCE. IN NO EVENT SHALL THE TOTAL CUMULATIVE LIABILITY OF THE QUIDD PARTIES FOR DIRECT DAMAGES ARISING UNDER THESE TERMS OF USE OR
                            RELATING THERETO, EXCEED, ONE HUNDRED DOLLARS ($100). BECAUSE SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR NEGLIGENCE, CONSEQUENTIAL, INCIDENTAL OR OTHER
                            DAMAGES, IN SUCH JURISDICTIONS THE QUIDD PARTIES’ LIABILITY IS LIMITED TO THE GREATEST EXTENT PERMITTED BY APPLICABLE LAW.
                        </p>
                        <h6>External Sites</h6>
                        <p>
                            The App may contain links to third-party websites (“External Sites“), but we do not endorse and are not responsible for the maintenance or content of any linked External Sites. Please refer to
                            the terms of use and privacy policies of the External Sites for more information
                        </p>
                        <h6>Indemnification</h6>
                        <p>
                            You agree to defend, indemnify, and hold the Quidd Parties harmless from and against any and all claims, actions, or demands and relating costs, damages and liability (including, without
                            limitation, reasonable attorneys’ fees) arising or resulting from: (i) your breach of these Terms of Use; (ii) your access to, use, or misuse of the App; (iii) any User Generated Content
                            provided through your account; (iv) your violation of any third-party right, including without limitation any intellectual property, or privacy right; or (v) any claims brought against any
                            Quidd Parties by another user, App Store or Third Party Provider as a result of any of your acts or omissions.
                        </p>
                        <h6>Compliance with Applicable Laws</h6>
                        <p>
                            The App is based in the United States. We make no claims concerning whether the App, the Quidd Content, User Generated Content, or any other content, functions or materials made available to
                            you through the App may be downloaded, viewed, or be appropriate for use outside of the United States. If you access the App, the Quidd Content, the User Generated Content or any other
                            content, functions or materials made available to you through the App from outside of the United States, you do so at your own risk. Whether inside or outside of the United States, you are
                            solely responsible for ensuring compliance with the laws of your specific jurisdiction.
                        </p>
                        <h6>Advertisements on the App</h6>
                        <p>
                            We, in our sole discretion, may post the advertisements of third parties on the App. The display of advertisements on the App is not intended as and does not in any manner constitute a
                            recommendation, endorsement, or approval of the advertiser or its services by us. Your correspondence or any other dealings with advertisers found on the website are solely between you and
                            such advertiser and we are not responsible or liable for the statements or conduct of any third party, nor for any loss or damage incurred as a result of any such dealings or as the result of
                            the presence of such advertisers on the website.
                        </p>
                        <h6>Termination</h6>
                        <p>
                            We may terminate these Terms of Use and your access to all or any part of the website at any time and for any reason without prior notice or liability. We reserve the right to change, suspend,
                            or discontinue all or any part of the website at any time without prior notice or liability.
                        </p>
                        <h6>Copyright Complaints</h6>
                        <p>If you believe the website contains any content that infringes your copyright, please contact our Copyright Agent, as detailed below, with the following information:</p>
                        <p>
                            An electronic or physical signature of the person authorized to act on behalf of the owner of the copyright interest;
                            <br />
                            A description of the copyrighted work that you claim has been infringed;
                            <br />
                            A description of where the material that you claim is infringing is located on the website;
                            <br />
                            Your address, telephone number and email address;
                            <br />
                            A statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent or the law; and
                            <br />
                            A statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the copyright owner or duly authorized to act on the copyright owner’s
                            behalf.
                            <br />
                            To contact our Copyright Agent by regular mail, please write to:
                        </p>
                        <p>
                            Copyright Agent
                            <br />
                            c/o Quidd, Inc.
                            <br />
                            61 E. 86th St #45
                            <br />
                            New York, NY 10028
                        </p>
                        <p>To contact our Copyright Agent by email, please write to contact@myquidd.com with COPYRIGHT NOTICE in the subject line</p>
                        <p>
                            Miscellaneous
                            <br />
                            In the event that any portion of this Agreement is held to be invalid or unenforceable, then such portion shall be construed in accordance with the applicable law as nearly as possible to
                            reflect the original intentions of the parties, and the remainder of this Agreement shall remain in full force and effect. This section, and the sections entitled Quidd Intellectual Property,
                            Indemnification, Quidd Disclaimers and Limitation of Liability shall survive the termination of this Agreement. You may not assign this Agreement. No waiver shall be effective unless in
                            writing. Neither the course of conduct between parties nor trade practice shall act to modify any provision of this Agreement. This Agreement shall be governed by and construed in accordance
                            with the laws of the State of New York. The parties hereby consent and agree to the exclusive jurisdiction of the state and federal courts located in New York for all suits, actions or
                            proceedings directly or indirectly arising out of or relating to this Agreement, and waive any and all objections to such courts, including but not limited to objections based on improper
                            venue or inconvenient forum, and each party hereby irrevocably submits to the jurisdiction of such courts in any suits, actions or proceedings arising out of or relating to this Agreement.
                        </p>
                        <p>
                            This Agreement contains the entire agreement of the parties concerning its subject matter, and supersede all existing agreements and all other oral, written or other communication between the
                            parties concerning the subject matter.
                        </p>

                        <h5>Privacy Policy</h5>
                        <p>
                            We at Quidd, Inc. (“Quidd,” “we,” “us,” or “our“) created this Privacy Policy (“Privacy Policy“) because we know that you care about how information you provide to us is used and shared. This
                            Privacy Policy relates to the information collection and use practices of Quidd in connection with our App and Website.
                        </p>
                        <p>
                            The terms of this Privacy Policy are incorporated by reference in the Terms of Use that you accept upon registering to use the App (the “Terms of Use”). By accepting the Terms of Use, you
                            accept the terms of this Privacy Policy. Capitalized terms not defined in this Privacy Policy shall have the meaning set forth in our Terms of Use.
                        </p>
                        <h6>Information We Collect</h6>
                        <p>In the course of providing the App, Quidd will collect (and/or receive) the following types of information. You authorize us to collect and/or receive such information.</p>
                        <p>
                            1. Personal Information
                            <br />
                            We do not collect any personal information from you unless you voluntarily provide it to us.
                        </p>
                        <p>
                            If you would like to use the App, you will have to download the App from the App Store using your App Store credentials. Such information may include your e-mail address, password, phone
                            number, and age (to ensure compliance with legal and contractual obligations). In this Privacy Policy, we refer to this information as “Personal Information.”
                        </p>
                        <p>
                            2. Other Information
                            <br />
                            In addition to the Personal Information, we may collect or receive additional information (collectively, the “Other Information“, and together with Personal Information, “Your Information“).
                            Such Other Information may include:
                        </p>
                        <p>1. From Your Activity. Information that we automatically collect when you use the App, including, without limitation:</p>
                        <p>
                            o IP address, which may consist of a static or dynamic IP address and will sometimes point to a specific identifiable computer or device; browser type and language; referring and exit pages
                            and URLs; date and time; amount of time spent on particular pages and other performance and usage data; and
                            <br />
                            o Information about your device, including the type of device; universally unique ID (“UUID“); advertising identifier (“IDFA“); MAC address; operating system and version (e.g., iOS, Android or
                            Windows); carrier and country location; hardware and processor information (e.g., storage, chip speed, camera resolution, NFC enabled); network type (WiFi, 3G, 4G, LTE); and similar data
                            <br />
                            o You may give us permission to collect your information in other services. For example, you may connect a social networking service such as Facebook to your discord account. When you do this,
                            it allows us to obtain information from those accounts.
                            <br />
                            1. From Cookies. We may use both session cookies, which expire once you close your web browser, and persistent cookies, which stay on your mobile device until you delete them and other
                            technologies to help us collect Other Information and to enhance your experience using the App. Cookies are small text files a website can use to recognize a repeat visitor to the website. We
                            may use cookies for various purposes, including to:
                        </p>
                        <h6>facilitate the sign in process for the App;</h6>
                        <p>
                            authenticate users;
                            <br />
                            personalize your experience
                            <br />
                            analyze which portions of the App are visited and used most frequently; and
                            <br />
                            measure and optimize advertising and promotional effectiveness.
                            <br />
                            If you do not want us to deploy cookies in your browser, you can opt out by setting your browser to reject cookies or to notify you when a website tries to put a cookie in your browser
                            software. If you choose to disable cookies in your browser, you can still use the App, although your ability to use some of the features may be affected.
                        </p>
                        <h6>Third-Party Analytics</h6>
                        <p>
                            We use third-party analytics services to evaluate your use of the App, compile reports on activity, collect demographic data, analyze performance metrics, and collect and evaluate other
                            information relating to the App and mobile and Internet usage. These third parties use cookies and other technologies to help analyze and provide us the data. By accessing and using the App,
                            you consent to the processing of data about you by these analytics providers in the manner and for the purposes set out in this Privacy Policy.
                        </p>
                        <h6>The Information Collected by or Through Third-Party Companies</h6>
                        <p>
                            You authorize us to share Personal Information or Other Information about your activity on the App with third parties for the purpose of providing advertisements you see on the App. These
                            third parties may use cookies, pixel tags (also called web beacons or clear gifs), and/or other technologies to collect Other Information for such purposes.
                        </p>
                        <h6>How We Use Your Information</h6>
                        <p>We will use your Personal Information and Other Information for the following purposes:</p>
                        <p>
                            To respond to your requests and to provide you with the App;
                            <br />
                            To respond to your inquiries and contact you about changes to the App;
                            <br />
                            To send you notices (for example, in the form of e-mails, mailings, and the like) regarding products or services you are receiving;
                            <br />
                            To improve the App;
                            <br />
                            To contact you regarding other products or services we offer;
                            <br />
                            For any other purposes disclosed at the time the information is collected or to which you consent;
                            <br />
                            Analyzing and improving our business, e.g. collecting information about how you use our App to optimize design and placement of certain features;
                            <br />
                            Customer service;
                            <br />
                            To protect users, personnel and property;
                            <br />
                            To enforce legal obligations around where content can be sold;
                            <br />
                            With your consent as provided through the Favorite Tool (as described below), to our third party vendors; and
                            <br />
                            As otherwise specifically described in this Privacy Policy
                            <br />
                            We may also use your information to improve our App, and may use cookies and other information to enable us to customize your user experience.
                        </p>
                        <p>
                            Your User Generated Content and your Username is viewable by other users of the App, including your social media contacts who are also users of the App. We are not responsible for any user’s
                            use of such information and their use of the information is not subject to this Privacy Policy. Please take care with the information, particularly any Personal Information, which you include
                            in your User Generated Content.
                        </p>
                        <p>
                            We, like many businesses, sometimes hire other companies to perform certain business-related functions. Examples include mailing information, maintaining databases, hosting services, and
                            processing payments. When we employ another company to perform a function of this nature, we provide them with the information that they need to perform their specific function, which may
                            include Personal Information. If we or all or substantially all of our assets are acquired, we expect that the information that we have collected, including Personal Information, would be
                            transferred along with our other business assets.
                        </p>
                        <p>
                            We may disclose your information, including Personal Information, to government authorities, and to other third parties when compelled to do so by government authorities, at our discretion, or
                            otherwise as required by law, including but not limited to in response to court orders and subpoenas. We also may disclose such information when we have reason to believe that someone is or
                            may be causing injury to or interference with our rights or property, other users of the App, or anyone else that could be harmed by such activities.
                        </p>
                        <h6>How We Protect Your Information</h6>
                        <p>
                            We take commercially reasonable steps to protect your information from loss, misuse, and unauthorized access, disclosure, alteration, or destruction. Please understand, however, that no
                            security system is impenetrable. We cannot guarantee the security of our databases, nor can we guarantee that the information you supply will not be intercepted while being transmitted to and
                            from us over the Internet. In particular, e-mail sent to or from us may not be secure, and you should therefore take special care in deciding what information you send to us via e-mail.
                        </p>
                        <h6>Control of Your Information</h6>
                        <p>You may update the information that is stored in your user account via the profile and settings pages on the App.</p>
                        <p>
                            Individuals who are residents of the European Union and some other jurisdictions outside the United States have certain legal rights to obtain confirmation of whether we hold personal data
                            about them, to access personal data we hold about them (including, in some cases, in portable form), and to obtain its correction, update, amendment or deletion in appropriate circumstances.
                            They may also object to our uses or disclosures of personal data, to request a restriction on its processing, or withdraw any consent, though such actions typically will not have retroactive
                            effect. They also will not affect our ability to continue processing data in lawful ways.
                        </p>

                        <h6>Some questions you may have, if these rights apply to you may include:</h6>
                        <p>
                            How can I access the personal data you have about me?
                            <br />
                            If you would like to submit a data access request, you can do some from the “Settings” page of the App, where there is a button to request your data by sending an e-mail to us. We will then
                            start to the process and provide you a link to access the personal data that we have on you within 30 days. We do have the right to ask that you confirm your identity, which may be done on a
                            case by case basis.
                        </p>
                        <p>
                            How can I correct, update, amend or delete personal data you have about me?
                            <br />
                            In addition to the functionality available through the “Settings” of the App, in which you can correct, updated, amend or delete certain personal data, you can also request other modifications
                            from us directly. Please write to us at contact@myquidd.com with the words “Personal Data Request” in the subject of your messages, along with an explanation of what data subject right you are
                            seeking to exercise. For your protection, we may take steps to verify identity before responding to your request.
                        </p>
                        <p>
                            How do I object or restrict the manner in which Quidd processes my personal data?
                            <br />
                            You have a right to ask us to stop using or limit our use of your personal data in certain circumstances –for example, if we have no lawful basis to keep using your data, or if you think your
                            personal data is inaccurate.
                        </p>
                        <p>
                            The rights and options described above are subject to limitations and exceptions under applicable law. You may choose to unsubscribe from our emails by following the instructions in the bottom
                            of the email. In addition to those rights, you have the right to lodge a complaint with the relevant supervisory authority. However, we encourage you to contact us first, and we will do our
                            very best to resolve your concern.
                        </p>
                        <h6>External Links</h6>
                        <p>
                            The App may contain links to External Sites. We do not endorse such External Sites and we are not responsible for the privacy practices of such sites. Please refer to the privacy policies of
                            those sites for more information on how the operators of those sites collect and use your Personal Information.
                        </p>
                        <h6>Important Notice to Non-U.S. Residents</h6>
                        <p>
                            The App or website is operated in the United States. If you are located outside of the United States, please be aware that any Personal Information and other information that you provide to us
                            will be transferred to the United States. By providing us with this information, you consent to this transfer.
                        </p>
                        <h6>Children</h6>
                        <p>
                            We do not knowingly collect Personal Information from children under the age of 13. If you are under 13, please do not give us any Personal Information. We encourage parents and legal
                            guardians to monitor their children’s Internet usage and to help enforce our Privacy Policy by instructing their children to never provide Personal Information through the internet without
                            their permission. If you have reason to believe that a child under the age of 13 has provided Personal Information to us, please contact us, and we will endeavor to delete that information
                            from our databases. For residents of the European Union, where processing of personal information is based on consent, we will not knowingly engage in that processing for users under the age
                            of consent established by applicable data protection law.
                        </p>
                        <p>As children’s privacy is important to us at Quidd, we have incorporated special measures (described below) to help children protect their privacy while using the App:</p>
                        <p>
                            There may be portions of the App that children can participate in. To enable participation, the Personal Information we collect upon registration will either allow or deny users the ability to
                            participate in features of the App.
                            <br />
                            To participate in certain features of the App, we may ask a user to register. When users who attempt to register indicate that they are children, we restrict access to content and features
                            that is appropriate for them. Generally, we either attempt to not collect personally identifiable information from them or direct them to the parent’s center in the App.
                        </p>
                        <p>
                            Unless we obtain consent, personally identifiable information collected from Children is generally used by Quidd or Quidd’s agents and contractors for internal purposes, such as providing
                            customer services and/or for the purposes for which the information was provided. We do not share personally identifiable information with outside third parties not bound by this Policy for
                            their own marketing purposes.
                            <br />
                            We may share children’s personally identifiable information with third parties to the extent reasonably necessary to (i) protect the security or integrity of our App; (ii) take precautions
                            against liability, (iii) respond to judicial process or law enforcement agency request or investigation, (iv) or to the extent permitted by law or consistent with this policy or legal
                            requirements.
                            <br />
                            If you would like to review any personally identifiable information that we have collected through the App from your child, have this information deleted, and/or request that there be no
                            further collection or use of your child’s information, you may email us at contact@myquidd.com.
                        </p>
                        <h6>California Privacy Rights</h6>
                        <p>
                            Under California Civil Code Section 1798.83, California residents who have an established business relationship with Quidd may choose to opt out of our sharing your Personal Information with
                            third parties for direct marketing purposes. If you are a California resident and (1) you wish to opt out; or (2) you wish to request certain information regarding our disclosure of your
                            Personal Information to third parties for the direct marketing purposes, please send an e-mail to contact@myquidd.com with “Privacy Policy” in the subject line or write to us at:
                        </p>
                        <p>
                            c/o Quidd, Inc.
                            <br />
                            61 E. 86th St #45
                            <br />
                            New York, NY 10028
                        </p>
                        <p>In addition, Quidd does not monitor, recognize, or honor any opt-out or do not track mechanisms, including general web browser “Do Not Track” settings and/or signals.</p>
                        <h6>Data Retention</h6>
                        <p>
                            We generally retain personal data for so long as it may be relevant to the purposes defined herein. To dispose of personal data, we may anonymize it, delete it or take other appropriate steps.
                            Data may persist in copies made for backup and business continuity purposes for additional time.
                        </p>
                        <h6>Changes to This Privacy Policy</h6>
                        <p>
                            This Privacy Policy is effective as of the date stated at the top of this Privacy Policy. We may change this Privacy Policy from time to time, and will post any changes on the App as soon as
                            they go into effect. By accessing and using the App after we make any such changes to this Privacy Policy, you are deemed to have accepted such changes. Please refer back to this Privacy
                            Policy on a regular basis.
                        </p>
                        <h6>How to Contact Us</h6>
                        <p>If you have questions about this Privacy Policy, please e-mail us at contact@myquidd.comand write “PRIVACY POLICY” in the subject line or mail us at the following address:</p>
                        <p>
                            c/o Quidd, Inc.
                            <br />
                            61 E. 86th St #45
                            <br />
                            New York, NY 10028
                        </p>
                    </Col>
                </Row>
            </Container>
        </SlidingPane>
    );
};

export default PrivacyPolicyAndTermsOfService;
