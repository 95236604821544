import React from "react";
import googlePlay from "../static/image/google_play.png";
import appStore from "../static/image/app_store.png";

function MarketLinks(props) {
    return (
        <div>
            <a href="https://play.google.com/store/apps/details?id=com.quidd.quidd&hl=en&gl=US" target="_blank">
                <img src={googlePlay} id="google-play-logo" />
            </a>
            <a href="https://apps.apple.com/us/app/quidd-digital-collectibles/id1063166978" target="_blank">
                <img src={appStore} id="app-store-logo" />
            </a>
        </div>
    );
}

export default MarketLinks;
