import React from "react";
import { useTranslation } from "react-i18next";
import BrandsLinks from "../components/BrandsLinks";
import googlePlay from "../static/image/google_play.png";
import appStore from "../static/image/app_store.png";

function TeaserFooter(props) {
    const { t } = useTranslation();

    return (
        <div id="teaser-footer-container">
            <div id="footer-content">
                <BrandsLinks />
                <div className="d-flex">
                    <span>
                        <span className="d-block no-wrap">
                            {t("JOIN")} <span className="font-weight-bold">{t("QUIDD")}</span>
                        </span>
                        {t("TODAY")}
                    </span>
                    <a href="https://play.google.com/store/apps/details?id=com.quidd.quidd&hl=en&gl=US" target="_blank">
                        <img src={googlePlay} className="app-market-logo" />
                    </a>
                    <a href="https://apps.apple.com/us/app/quidd-digital-collectibles/id1063166978" target="_blank">
                        <img src={appStore} className="app-market-logo" />
                    </a>
                </div>
            </div>
            <p className="text-xs text-center no-wrap">
                {t(`© 2021 Atari Interactive, Inc. All rights reserved. Atari and
                    the Atari logo are registered trademarks owned by Atari
                    Interactive, Inc. All individual Atari game names and logos are
                    trademarks or registered trademarks of Atari Interactive, Inc.
                    or its affiliates. Used with permission. All other trademarks
                    are properties of their respective owners.`)}
            </p>
        </div>
    );
}

export default TeaserFooter;
